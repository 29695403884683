.fadeIn {
  opacity: 1;
  transition: all 250ms linear 1s;
}

.fadeOut {
  opacity: 0;
  transition: all 250ms linear 1s;
}

.hidden * {
  display: none;
}
