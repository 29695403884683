:root {
  --height-section: 880px;
}

.App-logo {
  height: 100vh;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.home-img-mobile {
  position: absolute;
  left: -59.0127px;
  top: 295px;
  width: 449.6763px;
  height: 290.6937px;
}

.home-img-mobile-1 {
  position: absolute;
  left: 25.0961px;
  top: 270.4927px;
  width: 315.9px;
  height: 275.5073px;
}

.home-img-mobile-2 {
  position: absolute;
  left: -183.061px;
  top: 225px;
  width: 649.2464px;
  height: 496.9494px;
}

.home-img-mobile-4 {
  position: absolute;
  left: -183.061px;
  top: 162.7911px;
  width: 649.2464px;
  height: 496.9494px;
}

.home-img-mobile-6 {
  position: absolute;
  left: -67px;
  top: 163.9196px;
  width: 487.4517px;
  height: 414.5621px;
}

.home-img-mobile-7 {
  position: absolute;
  left: -73.6854px;
  top: 223.4273px;
  width: 536.5633px;
  height: 418.5727px;
}

.home-img-mobile-8 {
  position: absolute;
  left: -73.6854px;
  top: -376.5727px;
  width: 536.5633px;
  height: 418.5727px;
}

.home-img-mobile-9 {
  position: absolute;
  left: -63.0567px;
  top: 116.596px;
  width: 487.9554px;
  height: 68.638px;
}

.home-img {
  position: absolute;
  width: 1920px;
  line-height: 0;
}

.home-img-transition-wrapper {
  position: absolute;
  width: 100%;
}

.home-img-transition {
  width: 100%;
  height: var(--height-section);
}

.home-img-transition-2 {
  width: 100%;
  height: 1400px;
}

.home-img-transition-mobile {
  position: absolute;
  top: -275px;
  left: 0;
  height: 880px;
}

.home-img-transition-1-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-img-transition-4-mobile {
  position: absolute;
  top: 405px;
  left: -79px;
  width: 147vw;
  height: 795px;
}

.home-img-transition-4 {
  width: 100%;
  height: 1760px;
}

.home-img-table {
  width: 100%;
  height: 1028px;
}

.home-section-2 {
  height: 1028px !important;
  background: linear-gradient(#eaf1f4 86%, #ffffff 14%);
  z-index: 1 !important;
}

.home-section-4-1 {
  position: relative;
  padding-top: 40px;
  width: 100%;
  height: 100px;
  background: #eaf1f4;
  z-index: 10;
}

.home-section-8 {
  position: relative;
  height: var(--height-section);
  background: white;
  overflow: hidden;
}
.home-section-9 {
  position: relative;
  height: var(--height-section);
  background: #eaf1f4;
  overflow: hidden;
}

.home-content {
  position: absolute;
  height: 100%;
  width: 100%;
}

.home-content-0-button {
  text-transform: lowercase;
  background-image: url(static/home/button.svg);
  background-repeat: round;
  background-position: center;
  border-radius: 0px;
}

.home-content-1-mobile {
  margin: auto;
}

.home-content-1_wrapper {
  position: relative;
  height: 145px;
  width: 679px;
  margin-top: 211px;
}

.home-content-1_wrapper-mobile {
  position: relative;
  height: 52px;
  width: 234px;
  margin: auto;
  margin-top: 84px;
}

.pricing_asset_class {
  min-height: calc(100vh - 46px);
  min-width: 100vw;
  height: 100%;
  width: 100%;
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 5%;
}

.logo-item {
  opacity: 0.7;
  height: 100%;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}
